import React from 'react';
import { SRLWrapper } from "simple-react-lightbox";
import './Gallery.scss';
import { WidthContainer } from './WidthContainer';


export const Gallery: React.FC = () => {
    return (
        <SRLWrapper>
            <div className="Gallery" id="gallery">
                <WidthContainer>
                    <div className="Gallery__Grid">
                <a href="/gallery1.jpg">
                    <figure style={{ backgroundImage: 'url(/gallery1.jpg)' }}></figure>
                </a>
                <a href="/gallery2.jpg">
                    <figure style={{ backgroundImage: 'url(/gallery2.jpg)' }}></figure>
                </a>
                <a href="/gallery3.jpg">
                    <figure style={{ backgroundImage: 'url(/gallery3.jpg)' }}></figure>
                </a>
                <a href="/gallery4.jpg">
                    <figure style={{ backgroundImage: 'url(/gallery4.jpg)' }}></figure>
                </a>
                </div>
                </WidthContainer>
            </div>
        </SRLWrapper>
    )
}