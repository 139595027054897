import { Link } from "gatsby";
import * as React from "react";
import './Header.scss';
import { Link as ScrollLink } from 'react-scroll';

const Header: React.FC = (props) => (
  <header className="header">
    <Link to="/"><img className="header__logo" src={'/logo.svg'} alt="BD Belben and Son" /></Link>
    <ul className="header__navigation">
      <li><ScrollLink smooth={true} offset={50} duration={500} to="about">About Us</ScrollLink></li>
      <li><ScrollLink to="gallery" smooth={true} offset={50} duration={500}>Gallery</ScrollLink></li>
      <li><ScrollLink to="getintouch" smooth={true} offset={50} duration={500}>Get In Touch</ScrollLink></li>
    </ul>
  </header>
)

export default Header
