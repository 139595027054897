import React from 'react';
import './Hero.scss';

export const Hero: React.FC = () => {
    return (
        <div className="hero"> 

            <img src={'/hero-van.jpg'} alt="Hero image" />

            <h5 id="about">We've been doing this since 1965, <br />and we're going to be doing it a while longer</h5>
            <p>All fuels heating</p>
            <p>Bathroom design & installation</p>
            <p>General Plumbing</p>
        
        </div>
    )
}