import * as React from "react"
import Seo from "../components/Seo"
import Header from "../components/Header"
import { Hero } from "../components/Hero"
import { WidthContainer } from "../components/WidthContainer"
import SimpleReactLightbox from 'simple-react-lightbox';
import { Gallery } from "../components/Gallery"
import { GetInTouch } from "../components/GetInTouch"

const IndexPage = () => (
  <SimpleReactLightbox>
    <div>
      <Seo title="BD Belben and Son | Plumbing & Heating" />
      <Header />
      <WidthContainer>
        <Hero />
      </WidthContainer>
        <Gallery />
      <WidthContainer>
        <GetInTouch />
      </WidthContainer>
    </div>
  </SimpleReactLightbox>
)

export default IndexPage
